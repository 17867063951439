// Main page loader
import React from "react";

import "../assets/css/main.scss";
import PageHero from "./page_hero";
import MainIntro from "./main_intro";
import MainProduct from "./main_product";

const Main = () => {

    return (
        <div className="main-container">
            <PageHero />
            <MainIntro />
            <MainProduct />
        </div>
    );
};

export default Main;