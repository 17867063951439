import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import "../assets/css/header.scss";

import Logo from "../assets/images/logoCracklinHeader.png";

const Header = () => {

    let [showDropDownNav, setShowDropDownNav] = useState(false);

    const handleNavToggleClick = () => {
        setShowDropDownNav(!showDropDownNav);
    };

    const handleDropdownNavClick = () => {
        setShowDropDownNav(!showDropDownNav);
    };

    return (
        <div className="header-container">
            <div className="container header-flex-container">
                <div className="header-logo-container">
                    <Link to="/">
                        <img className="header-logo" src={Logo} alt={"logo"} />
                    </Link>
                </div>
                <div className="header-nav-container">
                    <button className="header-nav-toggle" onClick={handleNavToggleClick}><Icon icon="ic:outline-menu" /></button>
                    <ul className="header-dropdown-nav" style={{ display: showDropDownNav ? "flex" : "none" }}>
                        <li><Link to="/" onClick={handleDropdownNavClick}>Home</Link></li>
                        <li><Link to="/about-us" onClick={handleDropdownNavClick}>About Us</Link></li>
                        <li><Link to="/contact-us" onClick={handleDropdownNavClick}>Contact Us</Link></li>
                    </ul>
                    <ul className="header-nav">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;