
import imgHero01 from "../assets/images/imageHero01.jpg";

const useHeroImageService = () => {

    const getHeroImage = () => {

        // load hero image array
        let heroImages = [
            imgHero01
        ];

        // randomly select index for hero image array
        let index = heroImages.length * Math.random() | 0;

        let heroBackgroundStyle = {
            backgroundImage: `linear-gradient(rgba(200, 200, 170, 0.1), rgba(200, 200, 170, .1)), url(${heroImages[index]})`
        };

        return heroBackgroundStyle;
    };

    return {
        getHeroImage
    };

};

export default useHeroImageService;