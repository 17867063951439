// Main page loader
import React from "react";

import "../assets/css/about_us.scss";
import PageHero from "./page_hero";
import AboutUsIntro from "./about_us_intro";

const Main = (props) => {

    return (
        <>
            <div className="about-us-container">
                <PageHero />
                <AboutUsIntro />
            </div>
        </>
    );
};

export default Main;