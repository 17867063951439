import React from "react";

import "../assets/css/contact_us_hours.scss";

const ContactUsHours = () => {
    return (
        <div className="container contact-us-hours-container">
            <h3 className="page-title">Store Hours</h3>
            <div className="page-title-divider">
                <hr />
            </div>
            <div className="contact-us-hours-table-container mb-5">
                <table className="contact-us-hours-table">
                    <tbody>
                        <tr>
                            <td className="align-left">Monday</td>
                            <td className="align-right">Closed</td>
                        </tr>
                        <tr>
                            <td className="align-left">Tuesday</td>
                            <td className="align-right">11:00am - 6:00pm</td>
                        </tr>
                        <tr>
                            <td className="align-left">Wednesday</td>
                            <td className="align-right">11:00am - 6:00pm</td>
                        </tr>
                        <tr>
                            <td className="align-left">Thursday</td>
                            <td className="align-right">11:00am - 6:00pm</td>
                        </tr>
                        <tr>
                            <td className="align-left">Friday</td>
                            <td className="align-right">11:00am - 6:00pm</td>
                        </tr>
                        <tr>
                            <td className="align-left">Saturday</td>
                            <td className="align-right">11:00am - 6:00pm</td>
                        </tr>
                        <tr>
                            <td className="align-left">Sunday</td>
                            <td className="align-right">Closed</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ContactUsHours;