import React from "react";
import { Icon } from "@iconify/react";

import "../../assets/css/snackbar.scss";

const Snackbar = props => {
    let { type, message, iconColor, showAction, onAction } = props;
    return (
        <div className="snackbar-container">
            <div className="snackbar-message">
                <Icon icon={type.icon} style={{color: iconColor}} />
                <span>{message}</span>
            </div>
            {showAction && 
                <button className="snackbar-action" onClick={() => onAction()}>
                    Dismiss
                </button>
            }
        </div>
    );
};

export default Snackbar;