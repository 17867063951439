import React from "react";

import "../assets/css/main_product.scss";

import imgPopcornCaramel from "../assets/images/imagePopcornCaramel.jpg";
import imgPopcornCheesy from "../assets/images/imagePopcornCheesy.jpg";
import imgPopcornChocolate from "../assets/images/imagePopcornChocolate.jpg";
import imgPopcornCinema from "../assets/images/imagePopcornCinema.jpg";
import imgPretzels from "../assets/images/imagePretzels.jpg";
import imgRoastedAlmonds from "../assets/images/imageRoastedAlmonds.jpg";

const MainProduct = () => {
    return (
        <div className="container main-product-container">
            <div className="main-product-card">
                <div className="main-product-card-product-container">
                    <img src={imgPopcornChocolate} alt="Sweet Chocolate Flavors" />
                    <div className="main-product-card-product-text">
                        <span className="main-product-card-product-title">Sweet Chocolate Flavors</span>
                        <span className="main-product-card-product-descr">Our bold, sweet and extra rich chocolate covered flavors are sure to satisfy the sweet tooth of any chocolate lover!</span>
                        <ul className="main-product-card-product-flavors">
                            <li>Night &amp; Day Caramel</li>
                            <li>Sea Salt Night &amp; Day Caramel</li>
                            <li>Chocolate Peanut Butter</li>
                            <li>Frosted Cookie</li>
                            <li>Huckleberry Cheesecake</li>
                            <li>Lemon Meringue Pie</li>
                            <li>S'mores</li>
                            <li>Strawberry Cheesecake</li>
                            <li>Strawberry Lemon Swirl</li>
                        </ul>
                        <span className="pb-1">Available by the bag:</span>
                        <div className="main-product-card-product-pricing-container">
                            <div className="main-product-card-product-price-tag">
                                <span>Medium</span>
                                <span className="main-product-card-product-pricing">$10.00</span>
                            </div>
                            <div className="main-product-card-product-price-tag">
                                <span>Large</span>
                                <span className="main-product-card-product-pricing">$17.00</span>
                            </div>
                            <div className="main-product-card-product-price-tag">
                                <span>X-Large</span>
                                <span className="main-product-card-product-pricing">$28.00</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-product-card">
                <div className="main-product-card-product-container">
                    <img src={imgPopcornCaramel} alt="Handcrafted Coated Flavors" />
                    <div className="main-product-card-product-text">
                        <span className="main-product-card-product-title">Handcrafted Coated Flavors</span>
                        <span className="main-product-card-product-descr">You may love our caramel corn, but you should also try one of the many fruit, cola, dessert, licorice or candy flavored popcorns as well. They're amazing!</span>
                        <ul className="main-product-card-product-flavors">
                            <li>Old Fashioned Caramel</li>
                            <li>Sea Salt Caramel</li>
                            <li>Cherry</li>
                            <li>Cinnamon</li>
                            <li>English Toffee</li>
                            <li>Huckleberry</li>
                            <li>Lemon Drop</li>
                            <li>Mountain Dew</li>
                            <li>Red Hot Tamale</li>
                            <li>Strawberry</li>
                            <li>Strawberry Shortcake</li>
                            <li>Watermelon</li>
                        </ul>
                        <span className="pb-1">Available by the bag:</span>
                        <div className="main-product-card-product-pricing-container">
                            <div className="main-product-card-product-price-tag">
                                <span>Medium</span>
                                <span className="main-product-card-product-pricing">$8.00</span>
                            </div>
                            <div className="main-product-card-product-price-tag">
                                <span>Large</span>
                                <span className="main-product-card-product-pricing">$13.00</span>
                            </div>
                            <div className="main-product-card-product-price-tag">
                                <span>X-Large</span>
                                <span className="main-product-card-product-pricing">$23.00</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-product-card">
                <div className="main-product-card-product-container">
                    <img src={imgPopcornCheesy} alt="Distinctive Savory Flavors" />
                    <div className="main-product-card-product-text">
                        <span className="main-product-card-product-title">Distinctive Savory Flavors</span>
                        <span className="main-product-card-product-descr">Your family game night or evening of Netflix will be made complete with a bowl of our cheddar, jalapeno, salt &amp; vinegar or dilly flavored popcorn.</span>
                        <ul className="main-product-card-product-flavors">
                            <li>Cheddar Cheese</li>
                            <li>White Cheddar</li>
                            <li>Parmesan Garlic</li>
                            <li>All American</li>
                            <li>Bacon Cheddar</li>
                            <li>Bacon Jalapeno Cheddar</li>
                            <li>BBQ Bacon</li>
                            <li>Hell Fire Bacon Cheddar</li>
                            <li>Hell Fire Chili Cheese</li>
                            <li>Jalapeno Cheddar</li>
                            <li>Nacho Cheese</li>
                            <li>Really Dilly</li>
                            <li>Salt &amp; Vinegar</li>
                            <li>Spicy Dill</li>
                        </ul>
                        <span className="pb-1">Available by the bag:</span>
                        <div className="main-product-card-product-pricing-container">
                            <div className="main-product-card-product-price-tag">
                                <span>Individual</span>
                                <span className="main-product-card-product-pricing">$4.50</span>
                            </div>
                            <div className="main-product-card-product-price-tag">
                                <span>Family</span>
                                <span className="main-product-card-product-pricing">$9.00</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-product-card">
                <div className="main-product-card-product-container">
                    <img src={imgPopcornCinema} alt="Movie Night Delights" />
                    <div className="main-product-card-product-text">
                        <span className="main-product-card-product-title">Movie Night Delights</span>
                        <span className="main-product-card-product-descr">Top off your Netflix family night with our delightful classic theater popcorn.</span>
                        <ul className="main-product-card-product-flavors">
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Individual</span>
                                <span className="main-product-card-product-pricing">$1.50</span>
                            </li>
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Family</span>
                                <span className="main-product-card-product-pricing">$2.75</span>
                            </li>
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Pillow Pack (7 gallons)</span>
                                <span className="main-product-card-product-pricing">$11.75</span>
                            </li>
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Pillow Pack (15 gallons)</span>
                                <span className="main-product-card-product-pricing">$22.00</span>
                            </li>
                        </ul>
                        <span className="pb-1">Available by the bag.</span>
                    </div>
                </div>
            </div>
            <div className="main-product-card">
                <div className="main-product-card-product-container">
                    <img src={imgRoastedAlmonds} alt="Nutty and Sweet!" />
                    <div className="main-product-card-product-text">
                        <span className="main-product-card-product-title">Nutty and Sweet!</span>
                        <span className="main-product-card-product-descr">We now offer a selection of in-house roasted, deliciously sweet nuts that feature our individual favorites.</span>
                        <ul className="main-product-card-product-flavors">
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Sweet Almonds</span>
                                <span className="main-product-card-product-pricing">$7.00</span>
                            </li>
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Sweet Cashews</span>
                                <span className="main-product-card-product-pricing">$6.00</span>
                            </li>
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Sweet Pecans</span>
                                <span className="main-product-card-product-pricing">$8.00</span>
                            </li>
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Sweet Walnuts</span>
                                <span className="main-product-card-product-pricing">$8.00</span>
                            </li>
                            <li className="main-product-card-product-price-line">
                                <span className="main-product-card-product-price-line-label">Sweet P'nut</span>
                                <span className="main-product-card-product-pricing">$6.75</span>
                            </li>
                        </ul>
                        <span className="pb-1">Available by the bag.</span>
                    </div>
                </div>
            </div>
            <div className="main-product-card">
                <div className="main-product-card-product-container">
                    <img src={imgPretzels} alt="Pretzels!" />
                    <div className="main-product-card-product-text">
                        <span className="main-product-card-product-title">Pretzels!</span>
                        <span className="main-product-card-product-descr">We now offer savory spiced pretzels! You can really take care of those salty, crunchy urges with a bag of these tasty treats.</span>
                        <ul className="main-product-card-product-flavors">
                            <li>Camarillo Twisted</li>
                            <li>Cheddar Ranch</li>
                            <li>Dilly Ranch</li>
                            <li>Garlic Ranch</li>
                            <li>Mustard</li>
                            <li>Sweet Maui Onion</li>
                        </ul>
                        <span className="pb-1">Available by the bag:</span>
                        <div className="main-product-card-product-pricing-container">
                            <div className="main-product-card-product-price-tag">
                                <span>4 ounces</span>
                                <span className="main-product-card-product-pricing">$4.75</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainProduct;