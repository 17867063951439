import React from "react";

import useHeroImageService from "../service/heroImage_service";

import "../assets/css/page-hero.scss";
import logoCracklinPrimary from "../assets/images/logoCracklin_primary.png";

const PageHero = () => {
    let heroImageSvc = useHeroImageService();
    let heroBackgroundStyle = heroImageSvc.getHeroImage();

    return (
        <div className="container page-hero-container" style={heroBackgroundStyle}>
            <img src={logoCracklinPrimary} alt="Cracklin Gourmet Logo" />
        </div>
    );

};

export default PageHero;