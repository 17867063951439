import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import snackbar from "../mod/snackbar";

import templateType from "../enum/email_template_type";
import useMessageService from "../service/message_service";

import "../assets/css/contact_us_message.scss";

const ContactUsMessage = () => {
    const recaptchaRef = useRef();
    const messageService = useMessageService();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    let [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    let [recaptchaToken, setRecaptchaToken] = useState("");

    const handleToken = (token) => {
        setRecaptchaToken(token);
        setSubmitIsDisabled(false);
    };

    const handleTokenExpired = async () => {
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    const onSubmit = async (data) => {

        let messageData = {
            visitorName: data.name,
            visitorEmail: data.email,
            visitorPhone: data.phone,
            visitorMessage: data.message,
            templateName: templateType.CONTACT_US,
            recaptchaToken
        };
            
        let res = await messageService.sendMessage(messageData);
    
        if (res && res.status === 200) {
            snackbar.showSuccess("Success! Messsage sent!");
        } else {
            snackbar.showError("Failed! Unable to send message at this time.");
        }
        
        setValue("name", null);
        setValue("email", null);
        setValue("phone", null);
        setValue("message", null);
        setRecaptchaToken("");
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    return (
        <>
            <div className="container contact-us-message-container">
                <div>
                    <h2 className="page-title">Contact Us</h2>
                    <div className="page-title-divider">
                        <hr />
                    </div>
                </div>
                <div className="contact-us-form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="contact-us-form-input-field">
                            <label>Full Name<span className="form-required-asterisk"> *</span></label>
                            <input 
                                type="text"
                                placeholder="John Doe"
                                {...register("name", { 
                                    required: true })
                                }
                            />
                            {errors.name && <span className="contact-us-form-input-field-error validation-error">Your name is required</span>}
                        </div>
                        <div className="contact-us-form-input-field">
                            <label>Email Address<span className="form-required-asterisk"> *</span></label>
                            <input 
                                type="text"
                                placeholder="john.doe@example.com"
                                {...register("email", { required: true })} 
                            />
                            {errors.email && <span className="contact-us-form-input-field-error validation-error">A valid email is required</span>}
                        </div>
                        <div className="contact-us-form-input-field">
                            <label>Phone Number</label>
                            <input 
                                type="text"
                                placeholder="(999) 999-9999"
                                {...register("phone")} 
                            />
                        </div>
                        <div className="contact-us-form-input-field mb-3">
                            <label>Message Text</label>
                            <textarea
                                type="text"
                                {...register("message")} 
                            />
                        </div>
                        <input 
                            type="hidden" 
                            {...register("recaptchaToken")}
                            value={recaptchaToken}
                        />
                        <div className="mb-3">
                            <ReCAPTCHA 
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
                                onChange={handleToken}
                                onExpired={handleTokenExpired}
                                ref={recaptchaRef}
                            />
                        </div>
                        <button 
                            type="submit" 
                            disabled={submitIsDisabled}
                            className="form-submit-button contact-us-form-button" >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactUsMessage;