import React from "react";

import "../assets/css/contact_us_map.scss";

const ContactUsMap = () => {
    
    let mapKey = process.env.REACT_APP_GOOGLE_MAP_API;
    let mapAddress = "Cracklin Gourmet Popcorn, 8101 Fairview Ave, Boise, ID";

    return (
        <>
            <div className="container contact-us-map-container">
                <h2 className="page-title">Map</h2>
                <div className="page-title-divider">
                    <hr />
                </div>
                <div className="contact-us-map-embed-container">
                    <iframe className="contact-us-map-embed"
                        title="Cracklin Gourmet Popcorn"
                        loading="lazy"
                        frameBorder="0"
                        height="400"
                        src={`https://www.google.com/maps/embed/v1/place?key=${mapKey}&q=${mapAddress}`}
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </>
    );
};

export default ContactUsMap;