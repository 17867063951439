import React from "react";

import "../assets/css/contact_us.scss";

import PageHero from "./page_hero";
import ContactUsMessage from "./contact_us_message";
import ContactUsMap from "./contact_us_map";
import ContactUsHours from "./contact_us_hours";

const ContactUs = () => {
    return (
        <div className="contact-us-container">
            <PageHero text="Contact Us" />
            <ContactUsMessage />
            <ContactUsMap />
            <ContactUsHours />
        </div>
    );
};

export default ContactUs;