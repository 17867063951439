import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import "../assets/css/footer.scss";
import logoWhite from "../assets/images/logoCracklin_1024_darkBG.png";

const Footer = () => {

    let copyrightYear = new Date().getFullYear();

    return (
        <>
            <div className="footer-container mt-auto py-3">
                <div className="container footer-flex-container"> 
                    <div className="footer-logo-container">
                        <Link to="/">
                            <img className="footer-logo" src={logoWhite} alt={"logo"} />
                        </Link>
                    </div>
                    <div className="footer-address-container">
                        <div>
                            Cracklin Gourmet Popcorn<br />
                            8101 Fairview Ave<br />
                            Boise, ID 83704<br />
                            <br />
                            Phone: (208) 377-2100<br />
                            Email: info@cracklingourmet.com
                        </div>
                        <div className="footer-social-icon">
                            <a href="https://www.facebook.com/cracklingourmet/" target="_blank" rel="noreferrer">
                                <Icon icon="fa-brands:facebook" alt="facebook" />
                            </a>
                            <a href="https://www.instagram.com/cracklin_gourmet_popcorn/" target="_blank" rel="noreferrer">
                                <Icon icon="fa-brands:instagram" alt="instagram" />
                            </a>
                            <a href="https://www.yelp.com/biz/cracklin-gourmet-popcorn-boise" target="_blank" rel="noreferrer">
                                <Icon icon="fa-brands:yelp" alt="yelp" />
                            </a>
                        </div>
                    </div>
                    <div className="footer-copyright-text">
                        © {copyrightYear} Cracklin Gourmet Popcorn&nbsp;&nbsp;|&nbsp;&nbsp;This&nbsp;is&nbsp;
                        an <a href="https://everydvm.com" target="_blank" rel="noreferrer">EveryDVM</a>&nbsp;Website.
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;