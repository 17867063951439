import toast from "react-hot-toast";

import snackbarType from "../enum/snackbar_type";
import Snackbar from "../component/global/snackbar";

const DURATION = process.env.REACT_APP_SNACKBAR_DURATION;

const showError = (message, showAction = true) => {
    toast.custom(t => (
        <Snackbar 
            type={snackbarType.NEGATIVE} 
            iconColor="#FF5C5C"
            message={message} 
            showAction={showAction}
            onAction={() => toast.dismiss(t.id)} 
        />
    ), { duration: parseInt(DURATION) });
};

const showSuccess = (message, showAction = true) => {
    toast.custom(t => (
        <Snackbar 
            type={snackbarType.POSITIVE} 
            iconColor="#59E87D"
            message={message} 
            showAction={showAction}
            onAction={() => toast.dismiss(t.id)} 
        />
    ), { duration: parseInt(DURATION) });
};

const showWarning = (message, showAction = true) => {
    toast.custom(t => (
        <Snackbar 
            type={snackbarType.WARNING} 
            iconColor="#FFDA4C"
            message={message} 
            showAction={showAction}
            onAction={() => toast.dismiss(t.id)} 
        />
    ), { duration: parseInt(DURATION) });
};

const showInfo = (message, showAction = true) => {
    toast.custom(t => (
        <Snackbar 
            type={snackbarType.INFO} 
            iconColor="#FFFFFF"
            message={message} 
            showAction={showAction}
            onAction={() => toast.dismiss(t.id)} 
        />
    ), { duration: parseInt(DURATION) });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    showError,
    showSuccess,
    showWarning,
    showInfo
};