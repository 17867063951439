// eslint-disable-next-line import/no-anonymous-default-export
export default {
    NEGATIVE: {
        type: "negative",
        icon: "bi:x-circle"
    },
    POSITIVE: {
        type: "positive",
        icon: "bi:check-circle"
    },
    WARNING: {
        type: "warning",
        icon: "bi:exclamation-triangle"
    },
    INFO: {
        type: "info",
        icon: "bi:info-circle"
    }
};