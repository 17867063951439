import React from "react";

import "../assets/css/about_us_intro.scss";

const AboutUsIntro = () => {
    return (
        <div className="container about-us-intro-container">
            <h2 className="page-title">About Us</h2>
            <div className="page-title-divider">
                <hr />
            </div>
            <p className="page-lead">
                At Cracklin Gourmet Popcorn our mission is to produce the freshest quality product at a reasonable price while providing a fun family atmosphere and excellent customer service. We are a native Idaho family owned and operated business that prides itself in the creation of healthy, tasty and fun popcorn snacks.
            </p>
            <p className="page-text">
                Our facilities are meticulously maintained, inspected and certified to be clean and safe. Our equipment has been custom fabricated to pop the perfect kernel while removing the small pieces and “old maids” you don’t want. We then use the best ingredients to create a delicious product that is sure to satisfy your cravings. In fact, you might need to buy an extra bag for your drive home!
            </p>
            <p className="page-text">
                <strong>OUR COVID-19 POLICY:</strong> As a locally owned small business, we take our role and responsibility to our community very seriously. We continue to do everything we can to ensure the health and well-being of our customers, ourselves and ultimately the community we love during this unprecedented time of uncertainty. We continue to watch and diligently follow the guidelines set forth by Idaho’s Central District Health (CDH), the Centers for Disease Control (CDC), local, state and federal requirements specific to COVID-19. We are offering a variety of ways for you to continue purchasing our products:
            </p>
            <ul className="page-text">
                <li>Carry-out (“social distancing” will be strictly enforced)</li>
                <li>Curbside service</li>
                <li>Local delivery</li>
                <li>Shipping</li>
            </ul>
            <p className="page-text">
                To take advantage of curbside service, local delivery or shipping, simply call the store at <a className="page-text-link" href="tel:+12083772100(208)" rel="noreferrer">208-377-2100</a> to place your order. We will process your payment and make suitable delivery arrangements when you call.
            </p>
            <p className="page-text">
                We hope you are staying safe and healthy.<br />
                Your friends at Cracklin Gourmet Popcorn
            </p>
            <p className="page-text mb-5">
                Please <a className="page-text-link" href="https://www.facebook.com/cracklingourmet/" target="_blank" rel="noreferrer">follow us on Facebook</a> to learn more about how we go to great lengths to make sure your snacks are not only tasty but also safe!
            </p>
        </div>
    );
};

export default AboutUsIntro;